<template>
  <div class="privacy-policy">
    <h1>Política de Privacidade da Monitoramento Inteligente por Sensor (MIS)</h1>
    <br>
    <p>
      Bem-vindos à nossa Política de Privacidade e Proteção de Dados Pessoais. O MIS valoriza e respeita a privacidade
      dos seus clientes, com o intuito de garantir a confidencialidade e a segurança de todos os seus Dados Pessoais
      durante a prestação de nossos serviços e demais atividades inerentes ao nosso negócio.
      Por isso, este documento busca esclarecer de forma clara e objetiva como coletamos e tratamos dados pessoais e
      sensíveis ("dados pessoais") para a prestação dos serviços executados pela MIS, nos termos da Lei Geral de
      Proteção de Dados Pessoais (Lei nº 13.709/2018).
      Esta Política abrange nossas atividades de coleta de dados on-line e off-line, englobando os Dados Pessoais
      coletados por meio de nossos vários canais, incluindo – mas não se limitando a – sites na web, aplicativos, redes
      sociais de terceiros, serviço de atendimento ao consumidor, pontos de venda, pesquisas e eventos. Ressaltamos que
      poderemos agregar Dados Pessoais oriundos de diferentes fontes (por exemplo, nosso site e eventos off-line),
      combinando-os entre as diversas entidades da MIS.
      Quando você contrata os serviços da MIS, nos fornece seus dados pessoais para que possamos viabilizar a prestação
      dos nossos serviços e proporcionar a melhor experiência possível. Privacidade, segurança e transparência são
      valores fundamentais para nós; por isso, adotamos as melhores práticas para garantir a confidencialidade e
      integridade dos seus dados.
      Além disso, burocracia, letras miúdas e asteriscos não fazem parte da nossa missão. Desenvolvemos esta Política de
      Privacidade para explicar, de forma clara, simples e objetiva, as nossas práticas e os motivos para a coleta dos
      seus dados pessoais.
      Ao aceitar os termos desta Política de Privacidade, você está ciente de que a controladora dos seus dados pessoais
      é a MIS, inscrita no CNPJ sob o nº 26.134.051/0001-08, com sede no VENÂNCIO 2000, torre B50/60 - 8 andar, sala
      810, Brasília/DF, CEP 70.333-900.
      Após a leitura deste documento, se você tiver dúvidas, reclamações, ou desejar exercer os seus direitos
      relacionados aos seus dados pessoais, poderá entrar em contato conosco pelos nossos canais de atendimento através
      do e-mail coloca o contato@spezi.com.br ou pelo número de telefone (61) 2099-2433.
    </p>
    <br>
    <h2>Dados Pessoais</h2>
    <br>

    <p>
      Dados pessoais são quaisquer informações que, direta ou indiretamente, possam identificar pessoas físicas, tais
      como dados cadastrais, dados de saúde e identificadores eletrónicos.
    </p>

    <br>
    <h2>Compartilhamento de Dados Pessoais</h2>
    <br>
    <p>Para a prestação dos serviços, seus dados pessoais poderão ser compartilhados com:</p>
    <ul>
      <li>Fornecedores da plataforma e provedores de hospedagem.</li>
      <li>Prestadores de serviços e empresas terceirizadas.</li>
      <li>Empresas de meio de pagamento.</li>
      <li>Entidades públicas.</li>
    </ul>

    <br>
    <h2>Dados Pessoais Coletados</h2>
    <br>
    <p>Na prestação dos nossos serviços, poderão ser coletados os seguintes dados, de acordo com sua finalidade:</p>
    <ul>
      <li><strong>Dados cadastrais:</strong> Nome, Carteira de identidade (RG), Cadastro de Pessoa Física (CPF),
        endereço, e-mail.</li>
      <li><strong>Dados do dispositivo:</strong> Informações do dispositivo utilizado para acessar nossos serviços.</li>
      <li><strong>Dados de saúde:</strong> Informações contidas em prontuários, medicamentos utilizados, peso, altura.
      </li>
    </ul>
    <p>A coleta desses dados poderá ocorrer através de diversos canais de comunicação, como e-mail, telefone, website,
      serviços de teleatendimento, dentre outros.
    </p>

    <br>
    <h2>Por Quanto Tempo os Dados São Armazenados</h2>
    <br>
    <p>Armazenamos os seus dados pelo tempo necessário para a prestação dos serviços. Também manteremos os dados pelo
      período exigido por obrigações legais ou regulatórias, ou enquanto houver uma base legal que justifique sua
      retenção.</p>


    <br>
    <h2>Utilização dos Seus Dados</h2>
    <br>
    <p>Seus dados pessoais poderão ser utilizados para diversas finalidades, dentre as quais destacamos:</p>
    <ul>
      <li>Realização da telemedicina e atividades administrativas, como a comunicação dos resultados e o fornecimento de
        login e senha para acesso remoto.</li>
      <li>Cumprimento de obrigações legais e/ou regulatórias.</li>
      <li>Desenvolvimento e divulgação de novos produtos e serviços.</li>
      <li>Comunicação com o suporte técnico.</li>
    </ul>
    <p>Em ocasiões em que for necessário ou exigido por lei, poderemos solicitar seu consentimento de forma expressa,
      garantindo que você possa recusar ou retirar o consentimento sem impedimentos.</p>

    <br>
    <h2>Atendimento à Criança</h2>
    <br>
    <p>O atendimento a crianças (com até 12 anos de idade) será realizado mediante assinatura de termo de consentimento
      por um dos pais ou pelo responsável legal, autorizando o tratamento dos dados pessoais, conforme o artigo 14°, §§
      1° e 3° da Lei nº 13.709 (LGPD).</p>

    <br>
    <h2>Compartilhamento de Dados</h2>
    <br>
    <p>Podemos compartilhar dados pessoais nas seguintes hipóteses:</p>
    <ul>
      <li>Com autoridades judiciais, administrativas ou governamentais, sempre que houver determinação legal,
        requerimento, requisição ou ordem judicial.</li>
      <li>Com fornecedores de serviços de cloud (para hospedagem) ou com empresas responsáveis pela administração,
        guarda e segurança dos dados.</li>
      <li>Com fornecedores de meios de pagamento, para processar os pagamentos dos serviços prestados.</li>
      <li>Com empresas parceiras ou do grupo, para controle e execução de serviços prestados ou para atender interesses
        da empresa.</li>
    </ul>
    <p>Compartilharemos dados pessoais com terceiros apenas quando houver a sua anuência, obrigação legal ou quando o
      compartilhamento for indispensável para a prestação dos nossos serviços e o desenvolvimento de produtos, sempre
      observando a base legal e os procedimentos técnicos necessários.</p>

    <br>
    <h2>Tempo de Armazenamento de Dados</h2>
    <br>
    <p>Os dados pessoais serão armazenados:</p>
    <ul>
      <li>
        Enquanto forem necessários para cumprir as finalidades aqui descritas,
      </li>
      <li>
        Enquanto perdurar uma obrigação legal ou regulatória que exija sua manutenção.
      </li>
      <li>
        Pelo prazo legal para possível ajuizamento de demandas.
      </li>
      <li>
        Enquanto houver base legal ou regulatória que justifique sua retenção.
      </li>
    </ul>
    <p>
      Quando não houver mais justificativa para manter esses dados, eles serão apagados ou alterados de forma que não
      seja possível identificar o titular.
    </p>

    <br>
    <h2>Segurança dos Dados Pessoais</h2>
    <br>
    <p>Empregamos medidas técnicas e operacionais adequadas para proteger seus dados, exigindo o mesmo nível de
      segurança de nossos parceiros. No entanto, é importante salientar que nenhum sistema é completamente seguro.</p>
    <p>Caso identifique qualquer evento que possa comprometer os dados disponibilizados na nossa plataforma ou em nossos
      serviços, entre em contato conosco. Para reforçar a segurança, recomendamos que você:</p>
    <ul>
      <li>Utilize equipamentos e conexões seguras, navegadores e sistemas operacionais atualizados, além de antivírus.
      </li>
      <li>Evite compartilhar informações pessoais em outros sites, pois não solicitaremos dados fora dos nossos canais
        oficiais.</li>
      <li>Fique atento ao domínio de e-mails enviados por nossa equipe.</li>
    </ul>

    <br>
    <h2>Seus Direitos</h2>
    <br>
    <p>Você tem direito de:</p>
    <ul>
      <li>Confirmar a existência de tratamento de seus dados pessoais.</li>
      <li>Solicitar uma cópia dos dados pessoais mantidos em nossa base.</li>
      <li>Solicitar a correção de dados imprecisos, desatualizados ou incompletos.</li>
      <li>Solicitar a exclusão ou indisponibilidade dos dados, salvo em casos de obrigação legal.</li>
      <li>Solicitar a anonimização, bloqueio ou eliminação de dados excessivos ou tratados de forma contrária à lei.
      </li>
      <li>Solicitar a portabilidade dos dados para outros fornecedores de produtos e serviços, conforme regulamentação.
      </li>
      <li>Obter informações sobre as entidades com as quais compartilhamos seus dados.</li>
      <li>Ser informado sobre a possibilidade de não fornecer consentimento e suas consequências.</li>
      <li>Retirar seu consentimento para o processamento dos dados pessoais a qualquer momento.</li>
    </ul>

    <br>
    <h2>Alterações Nesta Política</h2>
    <br>
    <p>O MIS reserva-se o direito de alterar o teor desta Política a qualquer momento, seja para
      adequação, conformidade legal ou necessidade. As alterações entrarão em vigor imediatamente com a publicação da
      nova versão no site. Recomendamos que você verifique periodicamente este documento para estar ciente de quaisquer
      mudanças. Caso as alterações impliquem nova coleta de consentimento, você será devidamente notificado.</p>

    <br>
    <h2>Cookies</h2>
    <br>
    <p>
      Utilizamos cookies para coletar informações enquanto você navega em nosso website. Essas informações incluem: </p>
    <ul>
      <li>
        Padrões do seu navegador (tipo e idioma).
      </li>
      <li>Endereço IP.</li>
      <li>Ações realizadas nos websites do MIS (páginas visualizadas, links clicados, etc.).</li>
    </ul>

    <p>Cookies são pequenos arquivos digitais armazenados no seu dispositivo que guardam informações sobre suas
      preferências. Eles servem para:</p>
    <ul>
      <li>Aprimorar sua experiência de navegação, tanto em performance quanto em usabilidade.</li>
      <li>Memorizar informações sobre sua visita, idioma preferido, localização e recorrência de acesso.</li>
      <li>Compilar estatísticas anônimas e agregadas para entendermos como os usuários interagem com o nosso site.</li>
    </ul>

    <p>Utilizamos dois tipos de cookies:</p>
    <ul>
      <li><strong>Cookies de Sessão: </strong>Temporários, que são eliminados quando o navegador é fechado. </li>
      <li><strong>Cookies Persistentes: </strong> Permanecem no dispositivo até serem excluídos, conforme a duração
        definida e as configurações do navegador.</li>
    </ul>

    <p>Os cookies podem ser classificados em:</p>
    <ul>
      <li><strong>Estritamente Necessários: </strong>Permitem a navegação e o acesso a áreas seguras do website.</li>
      <li><strong>Analíticos: </strong>Coletam dados estatísticos anônimos para analisar o desempenho do site.</li>
      <li><strong>De Funcionalidade: </strong>Asseguram funcionalidades adicionais e memorizam preferências do usuário.
      </li>
      <li><strong>Específicos de Publicidade: </strong>Direcionam a publicidade de acordo com os interesses do usuário.
      </li>
    </ul>

    <p>A maioria dos navegadores aceita cookies automaticamente, mas você pode ajustar as configurações para bloqueá-los
      ou ser notificado quando forem enviados. Observe que desabilitar os cookies pode afetar a funcionalidade do site e
      limitar a personalização da sua experiência.</p>

    <br>
    <h2>Transferência Internacional de Dados</h2>
    <br>
    <p>Em situações específicas e para cumprimento de fins contratuais, a MIS poderá transferir dados pessoais para
      empresas, institutos e organizações internacionais. Nesses casos, os dados serão tratados de acordo com a
      legislação nacional e transferidos apenas para países que possuam leis de proteção de dados similares às do
      Brasil.</p>

    <br>
    <h2>Lei Aplicável e Foro</h2>
    <br>
    <p>Na medida do permitido por lei, esta Política é regida pelas leis da República Federativa do Brasil. Quaisquer
      demandas oriundas deste documento serão discutidas no foro da Circunscrição Judiciária de Brasília – DF, onde se
      encontra a sede do MIS, em prejuízo de qualquer outro foro, por mais privilegiado que seja.</p>

    <br>
    <h2>Dúvidas e Esclarecimentos</h2>
    <br>
    <p>Em atendimento às exigências legais, o MIS nomeou seu Encarregado de Dados, profissional
      responsável por receber reclamações e comunicações dos titulares, prestar esclarecimentos, orientar funcionários e
      contratados sobre as práticas de proteção dos dados pessoais, além de executar outras providências.</p>
    <p>Se você tiver qualquer dúvida ou desejar conversar sobre questões relacionadas aos seus dados, sinta-se à vontade
      para entrar em contato com nossa equipe.</p>

    <br>
    <h2>Aceitação da Política</h2>
    <br>
    <p>A aceitação desta Política será confirmada no momento em que você acessar nosso sistema Web e/ou usufruir dos
      nossos serviços, mesmo que de forma gratuita. Isso indicará que você está ciente e em total acordo com a forma
      como utilizaremos as suas informações e dados pessoais.</p>
    <p>Este documento foi elaborado para oferecer transparência e garantir que você esteja informado sobre os
      procedimentos de coleta, uso e proteção dos seus dados pessoais pela MIS.</p>
    <p>Caso necessite de qualquer esclarecimento adicional, entre em contato conosco pelos canais informados acima.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.privacy-policy h1,
h2 {
  color: #2c3e50;
}
</style>
